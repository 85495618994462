import VueRouter from 'vue-router'

const yearAndPillar = pillar => route => ({
  year: parseInt(route.params.year),
  pillar
})

export default new VueRouter({
  mode: 'history',
  base: '/DlgCert',
  routes: [
    {
      path: '/:orgUnitId?',
      name: 'companySelection',
      component: () => import('./components/CompanySelection')
    },
    {
      path: '/:orgUnitId/dashboard',
      name: 'dashboard',
      component: () => import('./components/CertDashboard')
    },
    // ecology
    {
      path: '/:orgUnitId/Ökologie/:year',
      component: () => import('./components/NavigationContainer'),
      props: yearAndPillar('ecology'),
      children: [
        {
          path: '',
          name: 'ecology',
          component: () => import('./components/EcologyOverview')
        },
        {
          path: 'Ackerbau',
          name: 'agriculture',
          component: () => import('./components/forms/EcologyAgricultureForm')
        },
        {
          path: 'Grünland',
          name: 'grassland',
          component: () => import('./components/forms/EcologyGrasslandForm')
        },
        {
          path: 'Pflanzenschutz',
          name: 'plantProtection',
          component: () => import('./components/forms/EcologyPlantProtectionForm')
        },
        {
          path: 'Bodengesundheit',
          name: 'soilHealth',
          component: () => import('./components/forms/EcologySoilHealthForm')
        },
        {
          path: 'Erosionsschutz',
          name: 'erosion',
          component: () => import('./components/forms/EcologyErosionForm')
        },
        {
          path: 'Wasserschutz',
          name: 'water',
          component: () => import('./components/forms/EcologyWaterForm')
        }
      ]
    },
    // economy
    {
      path: '/:orgUnitId/Ökonomie/:year',
      component: () => import('./components/NavigationContainer'),
      props: yearAndPillar('economy'),
      children: [
        {
          path: '',
          name: 'economy',
          component: () => import('./components/EconomyOverview')
        }
      ]
    },
    // social
    {
      path: '/:orgUnitId/Soziales/:year',
      component: () => import('./components/NavigationContainer'),
      props: yearAndPillar('social'),
      children: [
        {
          path: '',
          name: 'social',
          component: () => import('./components/SocialOverview')
        },
        {
          path: 'Personal',
          name: 'employees',
          component: () => import('./components/forms/SocialEmployeesForm')
        },
        {
          path: 'Gehalt',
          name: 'salary',
          component: () => import('./components/forms/SocialSalaryForm')
        },
        {
          path: 'Weiterbildung',
          name: 'training',
          component: () => import('./components/forms/SocialTrainingForm')
        },
        {
          path: 'Arbeitnehmerbelange',
          name: 'employeeMatters',
          component: () => import('./components/forms/SocialEmployeeMattersForm')
        },
        {
          path: 'Arbeitsschutz',
          name: 'safety',
          component: () => import('./components/forms/SocialSafetyForm')
        },
        {
          path: 'Engagement',
          name: 'engagement',
          component: () => import('./components/forms/SocialEngagementForm')
        }
      ]
    },
    // management
    {
      path: '/:orgUnitId/Management/:year',
      component: () => import('./components/NavigationContainer'),
      props: yearAndPillar('management'),
      children: [
        {
          path: '',
          name: 'management',
          component: () => import('./components/ManagementOverview')
        },
        {
          path: 'Risikomanagement',
          name: 'risk',
          component: () => import('./components/forms/ManagementForm'),
          props: {
            form: 'riskmanagement'
          }
        },
        {
          path: 'Compliance',
          name: 'compliance',
          component: () => import('./components/forms/ManagementForm'),
          props: {
            form: 'compliance'
          }
        }
      ]
    },
    // frs
    {
      path: '/:orgUnitId/Feldverwaltung/:year',
      component: () => import('./components/NavigationContainer'),
      props: yearAndPillar('frs'),
      children: [
        {
          path: '',
          name: 'frs',
          component: () => import('./components/FrsOverview')
        }
      ]
    },
    // certification
    {
      path: '/:orgUnitId/Ergebnisvorschau/:year',
      component: () => import('./components/CertificatePreview'),
      props: route => ({
        orgUnitId: route.params.orgUnitId,
        year: parseInt(route.params.year)
      }),
      children: [
        {
          name: 'certificatePreview',
          path: '',
          component: () => import('./components/preview/FullPreview')
        },
        {
          name: 'indicatorPreview',
          path: ':pillar/:indicator',
          component: () => import('./components/preview/details/IndicatorDetails'),
          props: true
        }
      ]
    }
  ]
})
