<template lang="html">
  <IxModuleRoot
    :translation-namespaces="['dlgCert', 'Common.SR_Common', 'Areas.FieldRecordSystem.SR_FieldRecordSystem', 'masterData.crops.usage']"
    :module="module"
    :module-path="['dlgCert']"
    :setup="loadPrerequisiteData"
    route="/DlgCert"
  >
    <div class="dlg-cert-root">
      <router-view />
    </div>

    <TranslationControls always-show />
  </IxModuleRoot>
</template>

<script>
import {mapActions} from 'vuex'
import IxModuleRoot from '@components/IxModuleRoot'
import TranslationControls from '@components/translation/TranslationControls'

import SelfDestruct from '@mixins/SelfDestruct'

export default {
  components: {
    IxModuleRoot,
    TranslationControls
  },
  mixins: [
    SelfDestruct
  ],
  computed: {
    module () {
      return () => import('@store/modules/dlg-cert')
    }
  },
  methods: {
    ...mapActions('dlgCert', [
      'loadPrerequisiteData'
    ])
  }
}
</script>

<style lang="scss" scoped>
::v-deep .dlg-cert-root {
  height: 100%;
  display: flex;
  flex-direction: column;

  padding: 5px 15%;

  --pillar-ecology: #58a942;
  --pillar-economy: #e1944f;
  --pillar-social: #f7ca18;
  --pillar-management: #7bd4fa;
  --pillar-frs: #141b33;
}
</style>
